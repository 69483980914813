import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  fetchWaitingList,
  fetchDocks,
  deleteWaitingMember,
  assignDock,
} from "../../API/marina";

import { dateFormat } from "../../utils/dateFormat";
import useQueryHook from "../useQueryHook";
import ButtonDark from "../../components/CommonComponents/ButtonDark";
import { useLocationStore } from "../../store/store";
import useMutationHook from "../useMutationHook";
import { message } from "antd";
import { useLocation } from "react-router-dom";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";
import useConstants from "./useConstants";
const GetDataForWaitingMembers = () => {
  const {
    userId,
    params: searchParams,
    setParams: setSearchParams,
    paramsObject,
    t,
    lang,
  } = useUserDataAndNavigation();
  const { assign, seasonalDockId } = useLocation().state ?? false;
  const { page, search } = useConstants();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { setIsModalOpen, isModalOpen } = useLocationStore();
  const [assignName, setAssignName] = useState("");
  const [assignDockModal, setAssignDockModal] = useState(false);

  const handleAssignDockModal = (bool) => {
    setAssignDockModal(bool);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSearchParams({ ...paramsObject, id: "" });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const { isLoading, data, error, refetch, isFetching } = useQuery({
    queryKey: ["waiting-list", page, searchParams.get("type"), search],
    queryFn: () =>
      fetchWaitingList(userId, page, searchParams.get("type"), search),
    staleTime: Infinity,
  });

  const {
    isLoading: docksLoading,
    data: docksData,
    error: docksError,
    refetch: docksRefetch,
    isFetching: docksFetching,
  } = useQueryHook(
    ["fetch-docks-list", searchParams.get("id")],
    async () => {
      return await fetchDocks(userId, searchParams.get("id"));
    },
    isModalOpen
  );

  const filterDocksData = docksData?.map((dock) => {
    return { key: dock.id, ...dock };
  });

  const filterData = data?.members.map((member) => {
    return { ...member, key: member?.id };
  });

  const selectOption = [
    {
      key: 1,
      label: t("allCustomer"),
      value: "",
    },
    {
      key: 2,
      label: t("oldest_newest"),
      value: "oldest",
    },
    {
      key: 3,
      label: t("biggest_lowest"),
      value: "biggest",
    },
    {
      key: 4,
      label: t("lowest_biggest"),
      value: "lowest",
    },
  ];

  const handleOptionsChange = (value) => {
    setSearchParams({ ...paramsObject, type: value, page: 1 });
  };

  const onSuccessAssign = ({ data }) => {
    const { message: successMessage } = data;
    message.success(successMessage);
    setSearchParams({ ...paramsObject, id: "" });
    setAssignDockModal(false);
    refetch();
  };

  const onErrorAssign = (error) => {
    const { data } = error?.response;
    message.error(data?.message);
  };

  const { mutate: updateDock } = useMutationHook(
    ["assign-dock"],
    async (Data) => {
      return assignDock(userId, Data?.waitListId, Data);
    },
    onSuccessAssign,
    onErrorAssign
  );

  const assignUserFromWaitlist = () => {
    updateDock({
      dockId: [searchParams.get("dockId")],
      waitListId: searchParams.get("id"),
    });
  };

  const assignDockId = (id, name) => {
    setSearchParams({ ...paramsObject, id });
    setAssignName(name);
    if (!isModalOpen && !assign) {
      setIsModalOpen(true);
    } else {
      setSearchParams({ ...paramsObject, dockId: seasonalDockId, id });
      handleAssignDockModal(true);
    }
  };
  const columns = [
    {
      title: t("boatType"),
      dataIndex: "boat_type",
    },
    {
      title: t("boatWidth"),
      dataIndex: "boat_width",
    },
    {
      title: t("boatLength"),
      dataIndex: "boat_length",
    },
    {
      title: t("name"),
      dataIndex: "customer_name",
    },
    {
      title: t("email"),
      dataIndex: "customer_email",
    },
    {
      title: t("Phonenumber"),
      dataIndex: "customer_contact",
    },
    {
      title: t("reported"),
      dataIndex: "join_date",
      render: (_, { id, join_date }) => {
        return dateFormat(join_date);
      },
    },
    {
      title: t("assignDock"),
      dataIndex: "4",

      render: (_, { id, customer_name }) => {
        return (
          <div className="flex items-center justify-center my-1 ">
            <ButtonDark
              styleClasses={"white-Btn !text-[12px]"}
              text={t("assignDock")}
              handleClick={() => assignDockId(id, customer_name)}
            />
          </div>
        );
      },
    },
  ];
  const onSuccess = (Data) => {
    const { message: response } = Data;
    message.success(response);
    setSelectedRowKeys([]);
    refetch();
  };

  const onError = (error) => {
    message.error(error);
  };
  const { mutate } = useMutationHook(
    ["delete-waiting-member"],
    async (Data) => {
      return await deleteWaitingMember(userId, Data);
    },
    onSuccess,
    onError
  );

  const deleteUser = () => {
    mutate({
      memberIds: selectedRowKeys,
    });
  };

  return {
    setSearchParams,
    columns,
    isLoading,
    data,
    error,
    refetch,
    isFetching,
    searchParams,
    selectOption,
    filterData,
    onSelectChange,
    selectedRowKeys,
    setIsModalOpen,
    docksData,
    filterDocksData,
    paramsObject,
    docksLoading,
    isModalOpen,
    docksRefetch,
    handleCloseModal,
    docksFetching,
    docksError,
    handleOptionsChange,
    deleteUser,
    assign,
    seasonalDockId,
    updateDock,
    assignName,
    t,
    assignDockModal,
    handleAssignDockModal,
    assignUserFromWaitlist,
    lang,
  };
};

export default GetDataForWaitingMembers;
