import React, { useRef, useState } from "react";
import { useUserDataAndNavigation } from "../../hooks/useUserDataAndNavigation";
import { Form, Progress, message } from "antd";
import { useForm } from "antd/es/form/Form";
import useGetDataForAddingAssets from "../../hooks/getData/useGetDataForAddingAssets";
import ButtonDark from "../CommonComponents/ButtonDark";
import AddAssetScreenOne from "./AddAssetScreenOne";
import AddAssetScreenTwo from "./AddAssetScreenTwo";

const AddAssetsForm = ({ assetType, step }) => {
  const { lang, t, parse, initialValues } = useUserDataAndNavigation();
  const inputRef = useRef(null);
  const isKayak = assetType === "kayak";
  const [form] = useForm();
  const isBoatTrailer = assetType === "boat_trailer";
  const myImages = localStorage.getItem("my-images");
  const handleUploadClick = () => {
    if (selectedImages.length >= 1) {
      setImagesUploadError("");
    }
    if (selectedImages.length > 3) {
      message.error(t("maximumLimitExceeded"));
    } else {
      inputRef.current.click();
    }
  };
  const {
    onFinish,
    loading,
    imagesUploadError,
    setImagesUploadError,
    screen,
    setScreen,
    navigate,
    selectedImages,
    setSelectedImages,
    removeImage,
    setRemoveImage,
    isDiscount,
    setIsDiscount,
    thumbnailImages,
    setThumbnailImages,
  } = useGetDataForAddingAssets();
  const assetTypeHeading =
    assetType.split("_").join("") === "boattrailer"
      ? "boatTrailer"
      : assetType.split("_").join("");
  return (
    <div className="h-full w-full flex flex-col items-center  py-16">
      <div className="w-[20%] mt-5">
        <Progress
          strokeColor={"#134357"}
          percent={step === 1 ? 35 : 80}
          trailColor="#D7EEF3"
          showInfo={false}
        />
      </div>
      {screen === 1 && (
        <>
          <h1 className="text-3xl mt-[52px] 2xl:text-4xl font-roobert-sb">
            {t("Filloutthedetails")}
          </h1>
          <h4 className="text-lg 2xl:text-xl font-semibold mt-6">
            {t("provide")} {t("informationaboutthe")} {t(assetTypeHeading)}
            {lang === "no" ? "en" : ""} {t("youwanttorentout")}
          </h4>
        </>
      )}

      <Form
        name="basic"
        onFinish={(values) => onFinish(values, isDiscount)}
        autoComplete="off"
        initialValues={initialValues}
        form={form}
        className="w-[80%]  mt-[50px] "
      >
        {screen === 1 && (
          <AddAssetScreenOne
            handleUploadClick={handleUploadClick}
            isBoatTrailer={isBoatTrailer}
            assetType={assetType}
            isKayak={isKayak}
            selectedImages={selectedImages}
            removeImage={removeImage}
            myImages={myImages}
            inputRef={inputRef}
            thumbnailImages={thumbnailImages}
            setThumbnailImages={setThumbnailImages}
            imagesUploadError={imagesUploadError}
            setSelectedImages={setSelectedImages}
            setRemoveImage={setRemoveImage}
          />
        )}
        {screen === 2 && (
          <AddAssetScreenTwo
            screen={screen}
            sizeInfeet={form.getFieldValue("size_in_feet")}
            assetType={assetType}
            form={form}
            isDiscount={isDiscount}
            setIsDiscount={setIsDiscount}
            t={t}
          />
        )}
        <div className="text-white  mt-[50px] gap-x-4 flex justify-center">
          <ButtonDark
            styleClasses={"dark-Btn h-[50px] !w-[140px] !px-[20px]"}
            text={"Back"}
            handleClick={() => {
              if (screen === 1) {
                navigate(-1);
              } else {
                setScreen(1);
              }
            }}
          />

          <ButtonDark
            styleClasses={"dark-Btn h-[50px] !w-[140px] !px-[20px]"}
            text={t("proceed")}
            type="submit"
            loading={loading}
            disabled={loading}
          />
        </div>
      </Form>
    </div>
  );
};

export default AddAssetsForm;
