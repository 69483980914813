import { useRef, useState } from "react";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";
import { message } from "antd";
import { searchPlaceName } from "../../utils/searchPlaceName";
import { addAssetStore } from "../../store/store";
import { useNavigate } from "react-router-dom";

const useGetDataForAddingGuestDock = () => {
  const {
    initialValues,
    parse,
    t,
    params,
    setParams,
    stringify,
    paramsObject,
  } = useUserDataAndNavigation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imagesUploadError, setImagesUploadError] = useState(null);
  const [removeImage, setRemoveImage] = useState(false);
  const myImages = localStorage.getItem("my-images");
  const { setActive } = addAssetStore();
  const [screen, setScreen] = useState(1);
  const [formData, setFormData] = useState({
    marina_name: initialValues.marina_name ?? "",
    number_of_guest_dock: initialValues.number_of_guest_dock ?? "",
    description: initialValues.description ?? "",
    rate_per_hour: initialValues.rate_per_hour ?? "",
    rate_per_day: initialValues.rate_per_day ?? "",
    electricity_rate_per_hour: initialValues.electricity_rate_per_hour ?? "",
    electricity_rate_per_day: initialValues.electricity_rate_per_day ?? "",
    water_rate_per_hour: initialValues.water_rate_per_hour ?? "",
    water_rate_per_day: initialValues.water_rate_per_day ?? "",
  });
  const [checkedList, setCheckedList] = useState({
    water: initialValues.is_water ?? false,
    electricity: initialValues.is_electricity ?? false,
  });
  const [isDiscount, setIsDiscount] = useState(
    initialValues.is_discount ?? false
  );
  const myThumbnailImages = localStorage.getItem("thumbnail-images");
  const [thumbnailImages, setThumbnailImages] = useState(
    myThumbnailImages ? parse(myThumbnailImages) : []
  );
  const [selectedImages, setSelectedImages] = useState(
    myImages ? parse(myImages) : []
  );
  const inputRef = useRef(null);

  const handleUploadClick = () => {
    if (selectedImages.length > 3) {
      message.error(t("maximumLimitExceeded"));
    } else {
      inputRef.current.click();
    }
  };

  const onFinish = async (values) => {
    if (selectedImages.length < 1) {
      return setImagesUploadError(t("atLeastOneImage"));
    }

    if (screen === 1) {
      setFormData((prevData) => ({
        ...prevData,
        ...values,
      }));
      setScreen(2);
    } else {
      const place_name = await searchPlaceName(
        params.get("lat"),
        params.get("lng")
      );
      if (place_name) {
        const dataToStore = {
          ...formData,
          ...values,
          is_discount: isDiscount,
          is_electricity: checkedList.electricity,
          is_water: checkedList.water,
          lat: params.get("lat"),
          lng: params.get("lng"),
          type: "guest_dock",
          place_name,
        };
        localStorage.setItem("values", stringify(dataToStore));
        setParams({ ...paramsObject, step: 3 });
        setActive(false);
      }
    }
  };
  return {
    loading,
    setLoading,
    imagesUploadError,
    setImagesUploadError,
    checkedList,
    setCheckedList,
    handleUploadClick,
    inputRef,
    selectedImages,
    setSelectedImages,
    isDiscount,
    setIsDiscount,
    removeImage,
    setRemoveImage,
    myImages,
    thumbnailImages,
    setThumbnailImages,
    onFinish,
    screen,
    setScreen,
    formData,
    setFormData,
    navigate,
  };
};

export default useGetDataForAddingGuestDock;
