import React from "react";

import SDSDetailsHeader from "../SeasonalDockSpots/SDSDetailsHeader";
import useGetDataForMarinaMembers from "../../hooks/getData/useGetDataForMarinaMembers";
import Spinner from "../CommonComponents/Spinner";
import EditMember from "./EditMember";
import { useLocationStore } from "../../store/store";
const MembersDetails = ({
  handleClose,
  mutateEdit,
  form,
  handleDeleteWaitingMember,
  t,
}) => {
  const { edit, setEdit } = useLocationStore();
  const { memberData, memberLoading, memberFetching } =
    useGetDataForMarinaMembers({ dashboard: true });
  const onDone = async () => {
    await form.submit();
  };

  const handleEdit = () => {
    setEdit(true);
  };
  if (memberLoading) {
    return <Spinner />;
  }

  if (memberFetching) {
    return <Spinner />;
  }

  const { customer_name, customer_contact, customer_email, customer_id } =
    memberData
      ? memberData
      : {
          customer_name: "",
          customer_contact: "",
          customer_email: "",
          customer_id: "",
        };

  return (
    <>
      <div className="px-[50px] text-dark-blue pt-[26px]">
        <SDSDetailsHeader
          handleClose={handleClose}
          handleEdit={handleEdit}
          onDone={onDone}
          edit={edit}
          showEdit={true}
          onDeleteUser={handleDeleteWaitingMember}
        />

        {!edit && (
          <div className="mt-[20px] text-dark-blue flex gap-x-[50px]">
            <ul className="">
              <li className="2xl:text-[35px] font-roobert-rg text-[28px] leading-[50px] ">
                {t("customerInfo")}
              </li>
              <li className="mt-[10px] 2xl:text-[22px] font-roobert-rg text-[20px]">
                {t("memberNumber")}: {customer_id}
              </li>
              <li className="2xl:text-[22px] font-roobert-rg text-[20px]">
                {t("name")}: {customer_name}
              </li>
              <li className="2xl:text-[22px] font-roobert-rg text-[20px]">
                {t("email")}: {customer_email}
              </li>
              <li className="2xl:text-[22px] font-roobert-rg text-[20px]">
                {t("Phonenumber")}: {customer_contact}
              </li>
            </ul>
            <ul className="">
              <li className="2xl:text-[35px] font-roobert-rg text-[28px] leading-[50px] ">
                {t("memberShip")}
              </li>
              <li className="mt-[10px] 2xl:text-[22px] font-roobert-rg text-[20px]">
                {t("registrationFee")}
              </li>
              <li className="2xl:text-[22px] font-roobert-rg text-[20px]">
                {t("memberFeeAnnual")}
              </li>
            </ul>
          </div>
        )}
      </div>
      {/* {!edit && (
        <PreviousInvoices
          invoiceColumns={invoiceColumns}
          invoicesData={invoicesData}
        />
      )} */}

      {edit && <EditMember mutateEdit={mutateEdit} form={form} t={t} />}
    </>
  );
};

export default MembersDetails;
