import { Tag, message } from "antd";
import React from "react";
import { useLocationStore } from "../../store/store";
import useMutationHook from "../useMutationHook";
import { useSearchParams } from "react-router-dom";
import { assignDock } from "../../API/marina";
import useConstants from "./useConstants";
const useGetDataForDockList = ({ refetch }) => {
  const { setIsModalOpen } = useLocationStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pierColumn, t } = useConstants();

  const userId = localStorage.getItem("userId");
  const paramsObject = Object.fromEntries(searchParams);

  const onSuccess = ({ data }) => {
    const { message: successMessage } = data;
    message.success(successMessage);
    setSearchParams({ ...paramsObject, id: "" });
    refetch();
    setIsModalOpen(false);
  };

  const onError = (error) => {
    const { data } = error?.response;
    message.error(data?.message);
  };
  const { mutate: updateDock } = useMutationHook(
    ["assign-dock"],
    async (Data) => {
      return assignDock(userId, searchParams.get("id"), Data);
    },
    onSuccess,
    onError
  );

  const handleSave = (selectedDock, setSelectedDock) => {
    setSelectedDock([]);
    updateDock({
      dockId: selectedDock,
    });
  };

  const dockListColumns = [
    {
      title: t("dock"),
      dataIndex: "id",
    },
    pierColumn,
    {
      title: t("length"),
      dataIndex: "dock_length",
    },
    {
      title: t("width"),
      dataIndex: "dock_width",
      key: "width",
    },
    {
      title: t("currentRenter"),
      dataIndex: "current_renter",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => {
        let color =
          status === "Available"
            ? "#F8F5DA"
            : status === "Active"
            ? "#D9F2DD"
            : "";
        return (
          <Tag
            color={color}
            key={status}
            className="!rounded-full my-1 leading-[28px] text-center  w-[98px]"
          >
            <div className="text-[16px] h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
              {status === "Active" ? t("active") : t("available")}
            </div>
          </Tag>
        );
      },
    },
    {
      title: t("boatFits"),
      dataIndex: "boat_fits",
      key: "boat_fits",
      render: (_, { boat_fits }) => {
        return (
          <div className="flex justify-center items-center">
            {boat_fits === "Yes" ? t("yes") : t("no")}
          </div>
        );
      },
    },
  ];

  return {
    dockListColumns,
    handleSave,
  };
};

export default useGetDataForDockList;
