import React, { useState } from "react";
import Layout from "../Layout/Layout";
import Table from "../components/CommonComponents/Table/Table";
import useGetDataForMarinaMembers from "../hooks/getData/useGetDataForMarinaMembers";
import ButtonDark from "../components/CommonComponents/ButtonDark";
import IconBtn from "../components/CommonComponents/IconBtn";
import CancelCircle from "../assets/icons/CancelCircle";
import PageHeaders from "../components/CommonComponents/PageHeaders";
import MembersDetails from "../components/MarinaMember/MembersDetails";
import { useNavigate, useParams } from "react-router-dom";
import SearchButton from "../components/CommonComponents/SearchButton";
import SearchBar from "../components/CommonComponents/SearchBar";
import { handleSearchClick, handleSearchChange } from "../utils/handleSearch";
import Dialog from "../components/CommonComponents/Modal/Dialog";
const ClubMembers = () => {
  const {
    columns,
    viewClubMemberModal,
    setViewClubMemberModal,
    filterMembers,
    data,
    mutateEdit,
    form,
    hanldeCloseModal,
    selectedRowKeys,
    onSelectChange,
    t,
    handleDeleteWaitingMember,
    isLoading,
    isFetching,
    searchParams,
    setSearchParams,
    error,
  } = useGetDataForMarinaMembers({ dashboard: false });
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const { lang } = useParams();
  return (
    <Layout page={t("Clubmembers")}>
      <Dialog
        isModalOpen={viewClubMemberModal}
        setIsModalOpen={setViewClubMemberModal}
        onCancelModal={hanldeCloseModal}
        component={
          <MembersDetails
            mutateEdit={mutateEdit}
            handleClose={hanldeCloseModal}
            form={form}
            t={t}
            handleDeleteWaitingMember={handleDeleteWaitingMember}
          />
        }
        styles={"club-members"}
      />
      <div className="px-[25px] waitingList pb-[108px]">
        <PageHeaders
          heading={t("Clubmembers")}
          exportData={true}
          importData={true}
          importUrl={`/${lang}/import/club-members`}
          sendInvoice={true}
          exportUrl={`/${lang}/export/club-members`}
        />
        <div className=" mt-[25px]  bg-[#FFFFFF] pb-[108px] rounded-[40px] my-table">
          <div className="w-full flex  pt-[30px] pb-[40px] items-center justify-between gap-x-[24px] pr-[30px]">
            <div className="flex gap-x-[10px] pl-[45px]">
              <SearchBar
                value={searchValue}
                placeholder={t("search") + " " + t("Clubmembers")}
                handleSearchChange={(e) =>
                  handleSearchChange(
                    e,
                    setSearchValue,
                    searchParams,
                    setSearchParams
                  )
                }
                handleSubmit={() =>
                  handleSearchClick(searchValue, setSearchParams, searchParams)
                }
              />
              <SearchButton
                text={t("search")}
                handleSearchClick={() =>
                  handleSearchClick(searchValue, setSearchParams, searchParams)
                }
              />
            </div>
            <div className="flex gap-x-[20px] items-center">
              {selectedRowKeys.length >= 1 && (
                <IconBtn
                  icon={<CancelCircle />}
                  styleClasses={"bg-[#FFC4B7]  border-none"}
                  text={t("deleteMember")}
                  handleClick={handleDeleteWaitingMember}
                />
              )}
              <ButtonDark
                styleClasses={
                  "light-Btn  !text-dark-blue    px-[21px]  !h-auto"
                }
                text={t("addMember")}
                handleClick={() => {
                  navigate(`/${lang}/import/club-members`);
                }}
              />
            </div>
          </div>

          <Table
            columns={columns}
            selection={true}
            data={filterMembers}
            onSelectChange={onSelectChange}
            selectedRowKeys={selectedRowKeys}
            pagination={true}
            totalPages={data?.total_pages * 20}
            pageSize={20}
            loading={isLoading || isFetching}
            error={error}
          />
        </div>
      </div>
    </Layout>
  );
};
export default ClubMembers;
