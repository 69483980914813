import React from "react";
import RentalPrice from "../Maps/RentalPrice";
import DiscountOffer from "../Maps/DiscountOffer";

const AddAssetScreenTwo = ({
  screen,
  sizeInfeet,
  assetType,
  form,
  isDiscount,
  setIsDiscount,
  t,
}) => {
  return (
    <div className={`  w-full  px-[50px] mt-[10px]`}>
      <RentalPrice
        step={screen}
        sizeInfeet={sizeInfeet}
        propertyType={assetType}
        form={form}
      />
      <div className="mt-[70px]">
        <DiscountOffer
          isDiscount={isDiscount}
          setIsDiscount={setIsDiscount}
          t={t}
        />
      </div>
    </div>
  );
};

export default AddAssetScreenTwo;
