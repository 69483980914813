import React, { useState } from "react";
import ButtonDark from "./ButtonDark";
import Cancel from "../../assets/icons/Cancel";
import AntdTable from "./Table/Table";
import useGetDataForDockList from "../../hooks/getData/useGetDataForDockList";

import Spinner from "./Spinner";

const DocksList = ({
  handleCloseModal,
  assignName,
  data,
  docksLoading,
  refetch,
  t,
}) => {
  const { dockListColumns, handleSave } = useGetDataForDockList({ refetch });
  const [selectedDock, setSelectedDock] = useState(null);
  if (docksLoading) {
    return <Spinner />;
  }
  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        if (record.boat_fits === "Yes") {
          setSelectedDock(record.key);
        }
      },
      style: {
        cursor: record.boat_fits === "Yes" ? "pointer" : "not-allowed",
      },
    };
  };

  return (
    <div className="text-dark-blue">
      <div className="flex justify-between items-center px-[33px] pt-[20px]">
        <h1 className="text-[28px] 2xl:text-[35px] font-roobert-rg leading-[50px]">
          {t("assignDockFor")} {assignName}
        </h1>
        <div className="flex items-center gap-x-[35px]">
          <ButtonDark
            text={t("save")}
            styleClasses={"light-Btn h-[40px]"}
            handleClick={() => handleSave(selectedDock, setSelectedDock)}
          />
          <Cancel handleClose={handleCloseModal} />
        </div>
      </div>
      <div className="table-list h-[400px] overflow-y-auto">
        <AntdTable
          columns={dockListColumns}
          selection={true}
          data={data}
          selectedRowKeys={[selectedDock]}
          onRow={onRow}
        />
      </div>
    </div>
  );
};

export default DocksList;
