import { useState } from "react";
import DashboardIcon from "../../assets/icons/DashboardIcon";
import RentalIcon from "../../assets/icons/RentalIcon";
import SeasonalDockIcon from "../../assets/icons/SeasonalDockIcon";
import WaitListIcon from "../../assets/icons/WaitListIcon";
import ClubMemberIcon from "../../assets/icons/ClubMemberIcon";
import ReportIcon from "../../assets/icons/ReportIcon";
import SupportIcon from "../../assets/icons/SupportIcon";
import SettingIcon from "../../assets/icons/SettingIcon";
import RentalObjectsIcon from "../../assets/icons/RentalObjectsIcon";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";
import { useParams } from "react-router-dom";
import { shutdown } from "@intercom/messenger-js-sdk";

const useGetDataForSidebar = () => {
  const [rental, setRental] = useState(false);
  const [assets, setAssets] = useState(false);
  const [docks, setDocks] = useState(false);

  const { t, navigate, location, role } = useUserDataAndNavigation();
  const [seasonalDockColor, setSeasonalDockColor] = useState(
    location.split("/")[2] === "seasonaldocks"
  );
  const [rentalColor, setRentalColor] = useState(
    location.split("/")[2] === "rentals"
  );
  const [assetsColor, setAssetsColor] = useState(
    location.split("/")[2] === "assets" ||
      (location.split("/")[2] && location.split("/")[3] === "assets")
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleConfirmLogout = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("role");
    localStorage.removeItem("userId");
    localStorage.removeItem("organizationName");
    localStorage.removeItem("addAssetsStore");
    localStorage.removeItem("addAssetsStore");
    setIsModalOpen(false);
    shutdown();
    navigate(`/${lang}/signin`);
  };

  const { lang } = useParams();
  const SideBarMenuForMarina = [
    {
      id: 1,
      name: t("dashboard"),
      path: "",
      nameId: "",
      icon: <DashboardIcon path={`/${lang}/`} location={location} />,
    },
    {
      id: 2,
      name: t("rentals"),
      path: `rentals/all-rentals`,
      nameId: "rentals",
      variable: rental,
      variableColor: rentalColor,
      setVariable: setRental,
      setVariableColor: setRentalColor,
      icon: <RentalIcon path={`rentals`} location={location.split("/")[2]} />,
      children: [
        {
          id: 21,
          name: t("ongoing"),
          path: "rentals/ongoing?page=1",
          nameId: "ongoing",
        },
        {
          id: 22,
          name: t("upcoming"),
          path: "rentals/upcoming?page=1",
          nameId: "upcoming",
        },
        {
          id: 23,
          name: t("requests"),
          path: "rentals/requests?type=pending&page=1",
          nameId: "requests",
        },

        {
          id: 24,
          name: t("archive"),
          path: "rentals/archive?page=1",
          nameId: "archive",
        },
      ],
    },

    ...(role !== "rental"
      ? [
          {
            id: 3,
            name: t("seasonalDocks"),
            path: "seasonaldocks",
            nameId: "seasonaldocks",
            variable: docks,
            variableColor: seasonalDockColor,
            setVariable: setDocks,
            setVariableColor: setSeasonalDockColor,
            icon: (
              <SeasonalDockIcon
                path={"seasonaldocks"}
                location={location.split("/")[2]}
              />
            ),
            children: [
              {
                id: 31,
                name: t("ongoing"),
                path: "seasonaldocks/ongoing/?page=1",
                nameId: "ongoing",
              },
              {
                id: 32,
                name: t("Waitlist"),
                path: "seasonaldocks/wait-list/?page=1",
                nameId: "wait-list",
              },
              // {
              //   id: 33,
              //   name: t("memberRequests"),
              //   path: "seasonaldocks/member-requests?page=1",
              //   nameId: "member-requests",
              // },
            ],
          },
          {
            id: 4,
            name: t("Waitlist"),
            path: "wait-list",
            nameId: "wait-list",
            icon: (
              <WaitListIcon path={`/${lang}/wait-list`} location={location} />
            ),
          },
          {
            id: 6,
            name: t("Clubmembers"),
            path: "club-members",
            nameId: "club-members",
            icon: (
              <ClubMemberIcon
                path={`/${lang}/club-members`}
                location={location}
              />
            ),
          },
        ]
      : []),
    {
      id: 5,
      name: t("rentalObjects"),
      path: "assets/all-assets",
      variable: assets,
      variableColor: assetsColor,
      setVariable: setAssets,
      setVariableColor: setAssetsColor,
      nameId: "assets",
      icon: (
        <RentalObjectsIcon path={"assets"} location={location.split("/")[2]} />
      ),
      children: [
        {
          id: 51,
          name: t("guestDocks"),
          path: "assets/guest-docks?page=1",
          nameId: "guest-docks",
        },
        ...(role !== "rental"
          ? [
              {
                id: 52,
                name: t("seasonalDocks"),
                path: "assets/permanent-docks?page=1",
                nameId: "permanent-docks",
              },
            ]
          : []),
        {
          id: 53,
          name: t("boats"),
          path: "assets/boat?page=1",
          nameId: "boat",
        },
        {
          id: 54,
          name: t("jetski"),
          path: "assets/jet_ski?page=1",
          nameId: "jet_ski",
        },
        {
          id: 55,
          name: t("kayak"),
          path: "assets/kayak",
          nameId: "kayak",
        },
        {
          id: 56,
          name: t("BoatTrailer"),
          path: "assets/boat-trailer",
          nameId: "boat-trailer",
        },
      ],
    },

    {
      id: 7,
      name: t("reports"),
      path: "reports",
      nameId: "reports",
      icon: <ReportIcon path={`/${lang}/reports`} location={location} />,
    },

    // {
    //   id: 9,
    //   name: "Club Members Requests",
    //   path:"map",
    //   nameId: "req",
    // },
    // {
    //   id: 8,
    //   name: "Customer Service",
    //   path:"map",
    //   nameId: "service",
    // },

    // {
    //   id: 10,
    //   name: t("map"),
    //   path:"map",
    //   nameId: "map",
    // },

    // {
    //   id: 12,
    //   name: "Calendar",
    //   path:"calendar",
    //   nameId: "support",
    //   icon: <SupportIcon location={location} path={"/support"} />,
    // },
  ];

  const bottomItems = [
    // {
    //   id: 1,
    //   name: t("support"),
    //   path: "support",
    //   nameId: "support",
    //   icon: <SupportIcon location={location} path={`/${lang}/support`} />,
    // },
    {
      id: 1,
      name: t("messages"),
      path: "messages",
      nameId: "messages",
      icon: (
        <SupportIcon
          location={location}
          // path={`/${lang}/support`}
        />
      ),
    },
    {
      id: 2,
      name: t("settings"),
      path: "settings",
      nameId: "settings",
      icon: <SettingIcon location={location} path={`/${lang}/settings`} />,
    },
  ];
  return {
    SideBarMenuForMarina,
    rental,
    assets,
    location,
    navigate,
    handleConfirmLogout,
    isModalOpen,
    t,
    setIsModalOpen,
    bottomItems,
  };
};

export default useGetDataForSidebar;
