import React, { useState } from "react";
import ViewDetails from "../../components/CommonComponents/ViewDetails";
import { Tag, message } from "antd";
import useQueryHook from "../useQueryHook";
import {
  acceptOrRejectRequests,
  getGuestDocksRequests,
  getSeasonsDocks,
} from "../../API/marina";
import { useParams } from "react-router-dom";
import { useLocationStore } from "../../store/store";
import { deleteParam } from "../../utils/deleteParam";
import useMutationHook from "../useMutationHook";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";
import useConstants from "./useConstants";
const useGetDataForSeasonalDocks = ({ enabled }) => {
  const { isModalOpen, setIsModalOpen, setAddRenter, setEdit } =
    useLocationStore();

  const { params, setParams, paramsObject, t, location, userId } =
    useUserDataAndNavigation();
  const { page, search, pierColumn, priceColumn, renterColumn } =
    useConstants();
  const [requestModalLoading, setRequestModalLoading] = useState(false);
  const [requestModal, setRequestModal] = useState({
    active: false,
    type: null,
  });

  const viewDockDetails = (id) => {
    setParams({ ...paramsObject, id });
    setIsModalOpen(true);
  };
  const type = params.get("type") || "all";
  const { filter } = useParams();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [requestedMember, setRequestedMember] = useState(false);
  const onSelectChange = (newSelectedRowKeys) => {
    const lastSelectedKey = newSelectedRowKeys[newSelectedRowKeys.length - 1];
    const selectedRental = filterData.find(
      (rental) => rental?.id === lastSelectedKey
    );
    if (selectedRental && selectedRental.request_status === "request") {
      setRequestedMember(true);
    } else {
      setRequestedMember(false);
    }
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const handleRequestModal = (bool, type) => {
    setRequestModal({
      active: bool,
      type,
    });
  };

  const { data, error, refetch, isLoading, isFetching } = useQueryHook(
    ["get-season-docks", page, filter, type, search],
    async () => {
      return await getSeasonsDocks(userId, page, filter, type, search);
    },
    enabled && location !== "/seasonaldocks/member-requests"
  );

  const {
    data: guestDocksRequest,
    isLoading: guestDocksRequestLoading,
    isFetching: guestDocksRequestFetching,
    error: guestDocksRequestError,
    refetch: guestDocksRefetch,
  } = useQueryHook(
    ["guest-dock-requests"],
    async () => {
      return await getGuestDocksRequests(userId);
    },
    location === "/seasonaldocks/member-requests"
  );
  let filterData = [];

  if (data?.members && location !== "/seasonaldocks/member-requests") {
    filterData = data.members.map((obj) => ({ key: obj.id, ...obj }));
  }

  if (guestDocksRequest && location === "/seasonaldocks/member-requests") {
    filterData = guestDocksRequest?.map((obj) => ({ key: obj.id, ...obj }));
  }
  const onSucces = (Data) => {
    guestDocksRefetch();
    setRequestModalLoading(false);
    message.success(Data.message);
    setSelectedRowKeys([]);
    handleRequestModal(false, null);
  };
  const onError = (error) => {
    const { data } = error.response;
    message.error(data.message);
    setRequestModalLoading(false);
  };
  const { mutate } = useMutationHook(
    ["accept-or-reject-requests"],
    async (Data) => {
      return await acceptOrRejectRequests(userId, Data);
    },
    onSucces,
    onError
  );
  const onClickAcceptOrReject = (type) => {
    setRequestModalLoading(true);
    mutate({
      requestIds: selectedRowKeys,
      requestStatus: type === "accept" ? "approved" : type,
    });
  };
  const dropDownProps = {
    options: [
      { value: "all", label: t("all") + " " + t("seasonalDocks") },
      { value: "a_z", label: t("pier") + " " + t("A-Z") },
      { value: "z_a", label: t("pier") + " " + t("Z-A") },
      {
        value: "width_highest",
        label: t("dock") + " " + t("width") + " " + t("highest_lowest"),
      },
      {
        value: "width_lowest",
        label: t("dock") + " " + t("width") + " " + t("lowest_highest"),
      },
      {
        value: "length_highest",
        label: t("dock") + " " + t("length") + " " + t("highest_lowest"),
      },
      {
        value: "length_lowest",
        label: t("dock") + " " + t("length") + " " + t("lowest_highest"),
      },
    ],
    defaultValue: "all",
    handleChange: (option) => setParams({ ...paramsObject, type: option }),
  };
  const columns = [
    {
      title: t("dock"),
      dataIndex: "dock_number",
    },
    pierColumn,
    ...(location === "/seasonaldocks/member-requests"
      ? [
          renterColumn,
          {
            title: t("vacancyPeriod"),
            dataIndex: "period",
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (_, { request_status }) => {
              let color =
                request_status === "approved"
                  ? "#D9F2DD"
                  : request_status === "request"
                  ? "yellow"
                  : "'#FFC4B7'";
              return (
                <Tag
                  color={color}
                  key={request_status}
                  className="!rounded-full  leading-[28px]  text-center  w-[98px]"
                >
                  <div className="text-[16px] h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
                    {request_status === "approved"
                      ? "Approved"
                      : request_status === "request"
                      ? "Request"
                      : "Rejected"}
                  </div>
                </Tag>
              );
            },
          },
        ]
      : [
          {
            title: t("name"),
            dataIndex: "customer_name",
          },
          {
            title: t("email"),
            dataIndex: "customer_email",
          },
          {
            title: t("Phonenumber"),
            dataIndex: "customer_contact",
          },
          {
            title: t("boatType"),
            dataIndex: "boat_type",
          },
          priceColumn,
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            // width: '25%',
            render: (_, { status, customer_id }) => {
              let color = !customer_id ? "#D9F2DD" : "#FFC4B7";
              return (
                <Tag
                  color={color}
                  key={status}
                  className="!rounded-full my-1 leading-[28px] text-center  w-[98px]"
                >
                  <div className="text-[16px] h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
                    {status}
                  </div>
                </Tag>
              );
            },
          },
          {
            title: t("details"),
            dataIndex: "4",
            render: (_, { id }) => {
              return (
                <div className="flex items-center justify-center">
                  <ViewDetails handleClick={() => viewDockDetails(id)} />
                </div>
              );
            },
          },
        ]),
  ];

  const memberRequests = location === "/seasonaldocks/member-requests";
  const handleClose = async () => {
    setIsModalOpen(false);
    setEdit(false);
    setAddRenter(false);
    setParams((prevParams) => {
      deleteParam(prevParams, "id");
    });
  };
  return {
    columns,
    data,
    isModalOpen,
    memberRequests,
    setIsModalOpen,
    location,
    filter,
    filterData,
    setEdit,
    isLoading,
    isFetching,
    handleClose,
    refetch,
    dropDownProps,
    onSelectChange,
    selectedRowKeys,
    requestedMember,
    handleRequestModal,
    requestModal,
    onClickAcceptOrReject,
    requestModalLoading,
    guestDocksRequestLoading,
    params,
    setParams,
    error,
    guestDocksRequestFetching,
    guestDocksRequestError,
  };
};

export default useGetDataForSeasonalDocks;
