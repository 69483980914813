import { Form } from "antd";
import FormInput from "../CommonComponents/FormInput";
import TextArea from "../CommonComponents/TextArea";
import Facilities from "./Facilities";
import ButtonDark from "../CommonComponents/ButtonDark";
import DiscountOffer from "./DiscountOffer";
import UploadImages from "./UploadImages";
import { useUserDataAndNavigation } from "../../hooks/useUserDataAndNavigation";
import useGetDataForAddingGuestDock from "../../hooks/getData/useGetDataForAddingGuestDock";
const AddGuestDocks = () => {
  const { initialValues, parse, stringify, t } = useUserDataAndNavigation();

  const {
    loading,
    imagesUploadError,
    checkedList,
    setCheckedList,
    handleUploadClick,
    inputRef,
    selectedImages,
    setSelectedImages,
    isDiscount,
    setIsDiscount,
    removeImage,
    setRemoveImage,
    myImages,
    thumbnailImages,
    setThumbnailImages,
    onFinish,
    screen,
    setScreen,
    navigate,
  } = useGetDataForAddingGuestDock();

  return (
    <div className="h-full text-dark-blue py-16 font-roobert-rg  flex flex-col items-center ">
      <h1 className="text-[28px] mb-[5px] 2xl:text-[35px] font-roobert-rg px-[50px] text-center  leading-[50px]">
        {t("addMarinaDetails")}
      </h1>
      <Form
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        initialValues={initialValues}
        className="w-[80%]"
      >
        <div className="w-full   ">
          <h1 className="text-[22px] mb-[5px] 2xl:text-[24px] font-roobert-rg">
            {t("marinaDetails")}
          </h1>

          {screen === 1 && (
            <div>
              <div className="w-full flex gap-x-[20px]">
                <div className="w-full">
                  <FormInput
                    name={"marina_name"}
                    placeHolder={t("nameOfMarina")}
                    styleClasses={"rounded-full  font-roobert-rg pl-[20px]"}
                    rules={[
                      {
                        required: true,
                        message: t("enterMarinaName"),
                      },
                    ]}
                  />
                </div>
                <div className="w-full">
                  <FormInput
                    name={"number_of_guest_dock"}
                    placeHolder={t("howManyGuestDocks")}
                    styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
                    rules={[
                      {
                        required: true,
                        message: t("enterNoOfGuestDocks"),
                      },
                    ]}
                    type={"number"}
                  />
                </div>
              </div>
              <div className="h-[200px] mt-[80px]">
                <TextArea
                  rows={5}
                  placeHolder={t("writeDesrciption")}
                  name="description"
                  styleClasses={
                    "!rounded-[20px] font-roobert-rg pt-[10px] pl-[20px]"
                  }
                  rules={[
                    {
                      required: true,
                      message: t("pleaseEnter") + " " + t("description") + "!",
                    },
                  ]}
                />
              </div>

              <div className="">
                <Facilities
                  checkedList={checkedList}
                  setCheckedList={setCheckedList}
                  t={t}
                />
              </div>

              <UploadImages
                selectedImages={selectedImages}
                handleUploadClick={handleUploadClick}
                setSelectedImages={setSelectedImages}
                inputRef={inputRef}
                stringify={stringify}
                parse={parse}
                removeImage={removeImage}
                setRemoveImage={setRemoveImage}
                myImages={myImages}
                error={imagesUploadError}
                t={t}
                type="guestDock"
                thumbnailImages={thumbnailImages}
                setThumbnailImages={setThumbnailImages}
              />
            </div>
          )}

          {screen === 2 && (
            <div>
              <div className="text-dark-blue mt-[30px]">
                <h1 className="text-[16px] font-roobert-rg leading-[28px]">
                  {t("fixedPrice")}
                </h1>
                <div className="grid grid-cols-2 gap-x-[20px] mt-[10px]">
                  <FormInput
                    name={"rate_per_hour"}
                    placeHolder={t("rate_per_hour") + "*"}
                    step="any"
                    styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
                    rules={[
                      {
                        required: true,
                        message:
                          t("pleaseEnter") + " " + t("rate_per_hour") + "!",
                      },
                    ]}
                    type={"number"}
                  />
                  <FormInput
                    name={"rate_per_day"}
                    step="any"
                    placeHolder={t("rate_per_day") + "*"}
                    styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
                    rules={[
                      {
                        required: true,
                        message:
                          t("pleaseEnter") + " " + t("rate_per_day") + "!",
                      },
                    ]}
                    type={"number"}
                  />
                </div>
              </div>
              <div className="text-dark-blue mt-[30px]">
                <h1 className="text-[16px] font-roobert-rg leading-[28px]">
                  {t("fixedPriceElectricity")}
                </h1>
                <div className="grid grid-cols-2 gap-x-[20px] mt-[10px]">
                  <FormInput
                    name={"electricity_rate_per_hour"}
                    step="any"
                    disabled={!checkedList.electricity}
                    placeHolder={t("rate_per_hour") + "*"}
                    styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
                    type={"number"}
                    rules={[
                      {
                        required: checkedList.electricity,
                        message:
                          t("pleaseEnter") +
                          " " +
                          t("electricity") +
                          " " +
                          t("rate_per_hour") +
                          "!",
                      },
                    ]}
                  />
                  <FormInput
                    name={"electricity_rate_per_day"}
                    placeHolder={t("rate_per_day") + "*"}
                    step="any"
                    disabled={!checkedList.electricity}
                    styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
                    type={"number"}
                    rules={[
                      {
                        required: checkedList.electricity,
                        message:
                          t("pleaseEnter") +
                          " " +
                          t("electricity") +
                          " " +
                          t("rate_per_day") +
                          "!",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="text-dark-blue mt-[30px]">
                <h1 className="text-[16px] font-roobert-rg leading-[28px]">
                  {t("fixedPriceWater")}
                </h1>
                <div className="grid grid-cols-2 gap-x-[20px] mt-[10px]">
                  <FormInput
                    name={"water_rate_per_hour"}
                    step="any"
                    disabled={!checkedList.water}
                    placeHolder={t("rate_per_hour") + "*"}
                    type={"number"}
                    styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
                    rules={[
                      {
                        required: checkedList.water,
                        message:
                          t("pleaseEnter") +
                          " " +
                          t("water") +
                          " " +
                          t("rate_per_hour") +
                          "!",
                      },
                    ]}
                  />
                  <FormInput
                    name={"water_rate_per_day"}
                    step="any"
                    placeHolder={t("rate_per_day") + "*"}
                    type={"number"}
                    styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
                    disabled={!checkedList.water}
                    rules={[
                      {
                        required: checkedList.water,
                        message:
                          t("pleaseEnter") +
                          " " +
                          t("water") +
                          " " +
                          t("rate_per_day") +
                          "!",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="mt-[50px]">
                <DiscountOffer
                  isDiscount={isDiscount}
                  setIsDiscount={setIsDiscount}
                  t={t}
                />
              </div>
            </div>
          )}
          <div className="text-center mt-[30px] w-full gap-x-3 flex justify-center">
            <ButtonDark
              styleClasses={"dark-Btn h-[50px] w-[140px]  !px-[20px]"}
              text={"Back"}
              loading={loading}
              handleClick={() => {
                if (screen === 1) {
                  navigate(-1);
                } else {
                  setScreen(1);
                }
              }}
            />
            <ButtonDark
              styleClasses={"dark-Btn h-[50px] w-[140px] !px-[20px]"}
              text={t("proceed")}
              type={"submit"}
              loading={loading}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddGuestDocks;
